<template>
  <section class="choose-usualLive-router">
    <search @search="search" @reset="reset" @changeKeyWord="changeKeyWord"></search>
    <div class="main">
      <div class="main-header">
        <div class="router2-name">常住居民列表</div>
        <div class="btn">
          <!-- <el-button type="primary" size="small" @click="changeComponent('randomSelect','分类统计')">分类统计</el-button> -->
          <el-button size="small" @click="exportExcel">导出EXCEL</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          id="table"
          v-loading="table.loading"
          :data="table.data"
          row-key="id"
          fit
          height="100%"
          :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" :index="indexMath" fixed></el-table-column>
          <el-table-column prop="nd" label="年度" fixed></el-table-column>
          <el-table-column prop="sfzhm" label="身份证号码" width="180" fixed></el-table-column>
          <el-table-column prop="xm" label="姓名" fixed></el-table-column>
          <el-table-column prop="xb" label="性别" width="50"></el-table-column>
          <el-table-column label="报名方式">
            <template>
              <span>随机抽选</span>
            </template>
          </el-table-column>
          <el-table-column prop="csrq" label="出生日期">
            <template slot-scope="scope">
              <span v-if="scope.row.csrq">{{scope.row.csrq}}</span>
              <span v-else>{{scope.row.sfzhm|csrq}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dhhm" label="联系方式"></el-table-column>
          <el-table-column prop="czdz" label="常住地址" width="300"></el-table-column>
          <el-table-column prop="mz" label="民族"></el-table-column>
          <el-table-column prop="gzdw" label="工作单位"></el-table-column>
          <el-table-column prop="whcd_a" label="文化程度"></el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
import search from '@/components/search.vue';
import UsualLive from '@/api/choose/usualLive';
import numberToString from '@/plugin/numberToString';

export default {
  // 常住居民名单
  name: 'usualLive',
  props: {},
  components: {
    search,
  },
  data() {
    return {
      componentName: '',
      table: {
        data: [],
        query: {
          keyword: '',
          page: 1,
          size: 100,
        },
        loading: false,
        total: 0,
      },
      title: '随机抽取',
      Dictionary: {},
    };
  },
  methods: {
    exportExcel() {
      this.$store.commit('changeLoading', {
        loading: true,
        title: '正在导出excel,由于数据可能较多，请稍后',
        color: 'rgba(255,255,255,0.8)',
      });
      let { total } = this.table;
      let { keyword } = this.table.query;
      UsualLive.list({
        page: 1,
        size: total,
        keyword,
      }).then((res) => {
        let data = numberToString(res.items, 'whcd', this.Dictionary.whcd.children);
        let cou = [
              { label: '序号', key: 'index', length: 100 },
              { label: '年度', key: 'nd', length: 100 },
              { label: '身份证号码', key: 'sfzhm', length: 180 },
              { label: '姓名', key: 'xm', length: 100 },
              { label: '性别', key: 'xb', length: 100 },
              { label: '报名方式', key: 'bmfs', length: 100, value: '随机抽选' },
              { label: '出生日期', key: 'csrq', length: 100 },
              { label: '联系方式', key: 'dhhm', length: 120 },
              { label: '常住地址', key: 'czdz', length: 400 },
              { label: '民族', key: 'mz', length: 100 },
              { label: '工作单位', key: 'gzdw', length: 300 },
              { label: '文化程度', key: 'whcd_a', length: 120 },
            ]
        console.log(data)
        // this.$exportExcel({
        //   name: `常住居民列表(${res.total}条)`,
        //   data: data,
        //   header: [
        //     { label: '序号', key: 'index', length: 100 },
        //     { label: '年度', key: 'nd', length: 100 },
        //     { label: '身份证号码', key: 'sfzhm', length: 180 },
        //     { label: '姓名', key: 'xm', length: 100 },
        //     { label: '性别', key: 'xb', length: 100 },
        //     { label: '报名方式', key: 'bmfs', length: 100, value: '随机抽选' },
        //     { label: '出生日期', key: 'csrq', length: 100 },
        //     { label: '联系方式', key: 'dhhm', length: 120 },
        //     { label: '常住地址', key: 'czdz', length: 400 },
        //     { label: '民族', key: 'mz', length: 100 },
        //     { label: '工作单位', key: 'gzdw', length: 300 },
        //     { label: '文化程度', key: 'whcd_a', length: 120 },
        //   ],
        // });
        this.$exportExcel(cou,data,`常住居民列表(${res.total}条)`)
      });
    },
    indexMath(index) {
      return index + (this.table.query.page - 1) * this.table.query.size + 1;
    },
    changeKeyWord(keyword) {
      this.table.query.keyword = keyword;
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    reset() {
      this.table.query = {
        page: 1,
        size: 100,
        keyword: '',
      };
      return this.getList();
    },
    search() {
      return this.getList();
    },
    getList() {
      console.log(this.Dictionary);
      this.table.loading = true;
      UsualLive.list(this.table.query)
        .then((res) => {
          let data = numberToString(res.items, 'whcd', this.Dictionary.whcd.children);
          console.log(data);
          this.table.data = data;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    let { whcd } = this.$store.getters.dic;
    this.Dictionary = { whcd };
    return this.getList();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.choose-usualLive-router {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .main {
    background-color: #fff;
    box-sizing: border-box;
    flex: 1;
    min-height: 0;
    border-bottom: 1px solid #ccc;
    position: relative;
    display: flex;
    flex-direction: column;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      box-sizing: border-box;
      .router2-name {
        color: $h4;
        padding: 15px 30px;
        font-weight: bold;
      }
      .el-button {
        margin-right: 20px;
      }
    }

    .table {
      flex: 1;
      padding-left: 30px;
      box-sizing: border-box;
      position: relative;
      padding-bottom: 50px;
      .el-table {
        width: calc(100% - 20px);
      }
    }
    .el-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
