<template>
  <div class="search-component">
    <div class="title">数据筛选</div>
    <div class="header-container">
      <div class="text">快速搜索：</div>
      <el-input v-model="keyword" placeholder="请输入关键词" size="small" @input="input"></el-input>
      <el-button type="primary" class="keyword" size="small" @click="$emit('search')">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'search-component',
  props: { loading: Boolean },
  components: {},
  data() {
    return {
      keyword: '',
    };
  },
  methods: {
    input() {
      this.$emit('changeKeyWord', this.keyword);
    },
    reset() {
      this.keyword = '';
      this.$emit('reset');
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.search-component {
  background-color: #fff;
  margin-bottom: 20px;
  .title {
    color: $h4;
    border-bottom: 1px solid $border1;
    padding: 15px 30px;
    font-weight: bold;
  }
  .header-container {
    padding: 15px 30px;
    display: flex;
    align-items: center;
    .keyword {
      margin-left: 20px;
    }
    .text {
      font-size: 14px;
      color: #666666;
    }
    .el-input {
      width: 224px;
    }
  }
}
</style>
